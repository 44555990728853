<template>
    <v-container fluid style="background-color: #f6f6f6">
      <v-row>
        <v-col md="12" lg="8" xl="9">
          <led-screen-grid-configurator />
        </v-col>
        <v-col md="12" lg="4" xl="3">
          <div class="mt-4 ma-3 text-left">
            <div class="text-h6 text-uppercase">Konfigurator ekranów LED</div>
            <div class="text-caption text-justify">Zapraszamy do skorzystania z naszego konfiguratora umożliwiającego oszaczowanie kosztów zamówienia i złożenie zapytania ofertowego. </div>
          </div>
          <led-order-amount-price />
          <led-order-send-request />
          <led-order-summary v-if="showAdditionalSummary" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <contact-popup button-label="Skontaktuj się w sprawie ekranów LED" topic="Ekrany LED" class="mb-6" />
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
import ContactPopup from "@/components/ContactPopup";

import LedScreenGridConfigurator from "@/components/products/led/LedScreenGridConfigurator";
import ledstore from "@/vuex/ledstore";
import LedOrderSummary from "@/components/products/led/LedOrderSummary";
import LedOrderAmountPrice from "@/components/products/led/LedOrderAmountPrice";
import LedOrderSendRequest from "@/components/products/led/LedOrderSendRequest";
export default {
  components: {
    LedOrderSendRequest,
    LedOrderAmountPrice,
    LedOrderSummary, LedScreenGridConfigurator, ContactPopup},
  data() {
    return {
      title: "Reklamowe matryce LED",
      subtitle: "",
      showAdditionalSummary: false,
    }
  },

  computed: {
    ledScreensLayout() {
      return ledstore.state.ledLayout
    }
  },

}

</script>
