<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-center ma-2">
          <v-snackbar
              color="primary"
              top
              v-model="executeSendMail"
          >
            <v-progress-linear color="secondary" class="mb-4" indeterminate />
            <div class="text-center" v-if="!mailSend">
              Wysyłanie wiadomości
            </div>
          </v-snackbar>
        </div>

        <div class="text-center ma-2">
          <v-snackbar
              color="success"
              top
              v-model="mailSend"
          >
            <div class="text-center">
              Wiadomość wysłana!
            </div>
          </v-snackbar>
        </div>

        <v-card class="pb-5 elevation-4">
          <v-card-title>
            Cena: {{price}} zł/netto
          </v-card-title>
          <v-card-subtitle class="text-left">
            <div class="text-uppercase">Ilość ekranów w zamówieniu: <span class="text-h4">{{amount}}</span></div>

            Prześlij zapytanie dla aktualnej konfiguracji ekranów LED
          </v-card-subtitle>
          <div>
            <v-form v-model="validForm"  class="mx-4" >
              <v-text-field
                  required
                  :rules="emailRules"
                  hint="Podaj adres email, pod którym mamy się z Tobą skontaktować."
                  v-model="mail"
                  class="mt-0 mb-0 pt-0"
                  :placeholder="this.$t('email_label')">

              </v-text-field>
              <div class="text-left text-sm-caption">
                Wybrana oferta ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego. Zastrzegamy sobie prawo zmiany podanych cen, czasów realizacji i parametrów oferowanych produktów.
              </div>
              <v-checkbox v-model="policyAgree">
                <template v-slot:label>
                  <div class="text-sm-caption">
                    Akceptuję warunki zawarte w
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                            target="_blank"
                            href="/privacy"
                            @click.stop
                            v-on="on"
                        >
                          Polityce Prywatności
                        </a>
                      </template>
                      Kliknięcie w powyższy odnośnik otworzy stronę w nowym oknie
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-btn
                  class="mb-0"
                  block
                  :disabled="!validForm || executeSendMail || !policyAgree"
                  @click="sendRequest">Wyślij pytanie</v-btn>

            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from "../../../config"
import axios from "axios";
import ledstore from "@/vuex/ledstore";


export default {

  data() {
    return {
      validForm: false,
      policyAgree: false,
      sendMail: true,
      mailSend: false,
      executeSendMail: false,
      mail: '',
      emailRules: [
        v => !!v || this.$t('email_required'),
        v => /.+@.+/.test(v) || this.$t('email_not_valid'),
      ],
      ledModuleWidth: ledstore.state.ledModuleWidth,
      ledModuleHeight: ledstore.state.ledModuleHeight,
    }
  },

  computed: {
    price() {
      return ledstore.state.price
    },
    layout() {
      return ledstore.state.ledLayout
    },
    amount() {
      return ledstore.state.screenAmount
    },
  },

  methods: {

    buildMailTemplate() {

      let row = document.createElement("div");
      row.className = "row";
      row.style.marginBottom = "10px";
      let data = this.layout;
      let containerRaw = "";
      let header = "<div style='display: block; margin-bottom: 30px;'>Witaj, ta wiadomość jest potwierdzeniem otrzymania Twojego zgłoszenia. Po jego przeanalizowaniu skontaktujemy pod wskazanym w formularzu adresem email.</div>";

      for (let i=0; i<data.length;i++) {
        row.innerHTML =
            "Konfiguracja nr " + (i+1) + ": " +
            (data[i].multiple > 1 ? " zestaw " + data[i].multiple + " ekranów" : "") +
            " wymiary: " + data[i].w * this.ledModuleWidth +
                          "mm x " +
                          data[i].h * this.ledModuleHeight
                          + "mm" +
            ", cena: "+  data[i].config_price + "zł/netto, " + this.getVariantLabelById(data[i].led_variant)
        ;
        containerRaw += row.outerHTML;
      }

      let priceDiv = "<div style='font-weight: 800; font-size: 1.3em; margin-bottom: 20px'>" + "Kwota zamówienia: " + this.price + "zł/netto</div>";
      return "<div>" + header + priceDiv + containerRaw + "</div>";

    },

    getVariantLabelById(variantId) {

      let variants = ledstore.state.ledVariants;

      for (let i=0; i<variants.length;i++) {
        if(variants[i].id === variantId)
          return variants[i].label
      }

    },

    sendRequest() {

      let app = this;
      this.mailSend = false;
      this.executeSendMail = true;
      let template = this.buildMailTemplate()
      let postData = {
        template: 'contact',
        title: "Ekrany LED - zapytanie ofertowe, kwota: " + this.price + "zł/netto",
        topic: "Ekrany LED - zapytanie ofertowe",
        message: {message: template, mail: this.mail}
      }

      if(this.sendMail)
      axios.post(config.MAIL_API_URL, postData)
          .then(() => {
            app.mailSend = true;
            app.executeSendMail = false;
          });
    }
  },
}
</script>
