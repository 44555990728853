<template>
  <v-container fluid >
    <v-container fluid class="ma-0 pa-0 elevation-0 mb-4">
      <v-row>
        <v-col>
          <grid-layout
              class="grid"
              :layout.sync="layout"
              :col-num="colNum"
              :row-height="70"
              :is-draggable="true"
              :is-mirrored="false"
              :vertical-compact="true"
              :max-rows="16"
              :margin="[0, 0]"
              :use-css-transforms="true"

              @layout-updated="layoutUpdatedEvent"
          >
            <grid-item v-for="item in layout"
                       refs="item"
                       :class="item.i === hoveredIndex ? 'hovered-grid-item' : ''"
                       @resize="resizedEvent"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :max-w=maxLedModules
                       :max-h="calculateMaxScreenHeight"
                       :i="item.i"
                       :key="item.i">
              <div class="led-screen-summary"
                   :class="getStyleClassByLedVariant(item)"
                   @mouseover="ledDisplayHovered(item.i)">

                <div>
                  <div v-if="item.multiple > 1">
                    Zestaw {{item.multiple}} ekranów:
                  </div>
                   {{calculateScreenDimensions(item.w, item.h)}}<br />
                   <b>{{item.config_price}}</b> zł/netto
                  <div>
                    <v-btn x-small  color="primary" outlined class="delete-item" v-if="layout.length > 1" @click="removeItem(item)">usuń</v-btn>
                  </div>
                </div>
              </div>
            </grid-item>
          </grid-layout>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
        </v-col>
      </v-row>
    </v-container>
<!--    <v-text-field label="maxymalna liczba modułów" type="number" v-model="maxLedModules"></v-text-field>-->
  </v-container>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import ledstore from "@/vuex/ledstore";

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },

  computed: {
    calculateMaxScreenHeight() {
      return Math.floor(this.maxLedModules / this.resizedScreenWidth) / this.resizedScreenWidth * this.resizedScreenWidth;
    },
    hoveredIndex() {
      return ledstore.state.ledDisplayHoveredIndex
    },

    layout: {
      get() {
        return ledstore.state.ledLayout
      },
      set(newValue) {
        return newValue
      }
    }
  },

  created() {
    this.updateStoredLayout()
  },

  watch: {
    layout: {
      handler() {
        this.updateStoredLayout()
        this.layoutUpdatedEvent(this.layout)
      },
      deep: true
    },

    price(newPrice) {
      ledstore.commit("setPrice", newPrice)
    }
  },

  methods: {

    calculateScreenDimensions(w, h) {

      let screenWidth = this.moduleWidth * w;
      let screenHeight = this.moduleHeight * h;
      return screenWidth + "mm x " + screenHeight + "mm";
    },

    updateStoredLayout() {
      ledstore.commit("setLedLayout", this.layout)
      ledstore.commit("setScreenAmount", this.calculateAmountOfScreens())
      this.layoutUpdatedEvent(this.layout)
    },

    getLabelByIndex(index) {
      let labels = ["64x32", "32x32"]
      return "Matryca " + labels[index % 2];
    },

    resizedEvent: function(i, newH, newW){
      // console.debug("RESIZED i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx);
      this.resizedScreenWidth = parseInt(newW);
    },

    calculateAmountOfScreens() {

      let currentLayout = this.layout;
      let currentLayoutLength = this.layout.length;
      let result = 0;
      for (let i=0; i<currentLayoutLength; i++) {

        if(currentLayout[i].multiple) {
          result = result + parseInt(currentLayout[i].multiple);
        }
        else {
          result++;
        }
      }
      return result;
    },

    getConfigPriceByVariant(variant) {

      let intVariant = parseInt(variant);
      let ledVariants = ledstore.state.ledVariants;
      for(let i=0; i<ledVariants.length;i++) {
        if(ledVariants[i].id === intVariant)
          return ledVariants[i].price
      }
      return false;
    },


    getStyleClassByLedVariant(config) {

      let elevation = "";
      if(config.i === this.hoveredIndex) {
        elevation = "elevation-12";
      }
      let variant = config.led_variant
      let style = elevation + " led-";
      let cssVariant = "other";

      switch (variant) {
        case 1:
          cssVariant = 'red';
          break;
        case 2:
          cssVariant = 'green'
          break;
        case 3:
          cssVariant = 'blue'
          break;
        case 4:
          cssVariant = 'rgb'
          break;
      }

      return style + cssVariant;

    },

    removeItem(item) {

      let newLayout = this.layout.filter(data => data.i !== item.i);
      ledstore.commit("setLedLayout", newLayout)
    },

    ledDisplayHovered(displayIndex) {
      ledstore.commit("setLedDisplayHoveredIndex", displayIndex)
    },

    layoutUpdatedEvent: function(newLayout){
      let app = this;
      let calculatePrice = 0;
      this.layout = newLayout;

      newLayout.forEach((item) => {

        let variant = item.led_variant ? item.led_variant : 1;
        let multipleScreen = item.multiple ? item.multiple : 1;
        let screenPrice = (item.w * item.h * app.getConfigPriceByVariant(variant)) + app.baseLedDisplayPrice;
        let configurationPrice = screenPrice * multipleScreen;
        calculatePrice = calculatePrice + configurationPrice;
        item.config_price = configurationPrice;
        item.screen_price = screenPrice;
        item.led_variant = variant;

      })
      app.price = calculatePrice;

    },
  },

  data() {
    return {
      maxLedModules: 16,
      resizedScreenWidth: 2,
      resizeEvent: null,
      price: 0,
      colNum: 12,
      baseLedDisplayPrice: ledstore.state.baseLedDisplayPrice,
      moduleWidth: ledstore.state.ledModuleWidth,
      moduleHeight: ledstore.state.ledModuleHeight
    }
  },
}
</script>

<style>

.led-red {
  background-color: rgba(255, 0, 0, 0.15) !important;
}

.led-green {
  background-color: rgba(26, 160, 0, 0.15) !important;
}

.led-blue {
  background-color: rgba(0, 0, 255, 0.15) !important;
}

.led-rgb {
  background:
      linear-gradient(
          217deg,
          rgba(255, 0, 0, 0.8),
          rgba(255, 0, 0, 0) 70.71%
      ), linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
}

.vue-grid-item {
  border: 1px solid #333;
}

.hovered-grid-item {
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid #cccccc;
  color: #ffffff;
}

.led-screen-summary {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75em;
  font-weight: 700;
}

.delete-item:hover {
  cursor: pointer;
}

.grid {
  content: '';
  background-size: calc(calc(100% - 1px) / 12) 70px;
  background-image: linear-gradient(
      to right,
      #adbdd0 1px,
      transparent 1px
  ),
  linear-gradient(to bottom, #adbdd0 1px, transparent 1px);
  height: calc(100% - 5px);
  width: calc(100% - 5px);
  background-repeat: repeat;
  margin:0;
  min-height: calc(70px * 14);
}

</style>
