import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ledLayout: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 1,
                i: 0,
                multiple: 1
            }
        ],
        ledVariants: [
            {id: 1, label: "Ekran o rastrze 10mm, czerwony", price: 120},
            {id: 2, label: "Ekran o rastrze 10mm, zielony", price: 120},
            {id: 3, label: "Ekran o rastrze 10mm, niebieski", price: 110},
            {id: 4, label: "Ekran wielokolorowy, raster 5mm", price: 260},
        ],
        price: 0,
        screenAmount: 0,
        ledModuleWidth: 320,
        ledModuleHeight: 160,
        baseLedDisplayPrice: 420,
        ledDisplayHoveredIndex: -1,
    },
    mutations: {
        setLedLayout(state, layout) {
            state.ledLayout = layout
        },
        setPrice(state, price) {
            state.price = price
        },
        setScreenAmount(state, screenAmount) {
            state.screenAmount = screenAmount
        },

        setLedDisplayHoveredIndex(state, displayIndex) {
            state.ledDisplayHoveredIndex = displayIndex
        }
    }
})
