<template>
<v-container>
  <v-row>
    <v-col>
      <v-card  class="elevation-4 text-left pb-4">
        <div v-if="showPrice">
          <v-card-title>
            Cena: {{price}} zł/netto
          </v-card-title>
          <v-card-subtitle>

            <span class="text-uppercase">Ilość ekranów w zamówieniu: <span class="text-h4">{{amount}}</span></span>
          </v-card-subtitle>
        </div>
        <v-divider></v-divider>
        <div>
          <led-screen-select />
        </div>

        <v-divider></v-divider>
        <div class="ma-2 elevation-0">
          <v-data-table
              :headers="headers"
              :items="layout"
              @click:row="onRowClicked"
              @item-expanded="onItemExpanded"
              :expanded.sync="expanded"
              item-key="i"
              :items-per-page="5"
              show-expand
              :single-expand="true"
              class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Twoje zamówienie</v-toolbar-title>
              </v-toolbar>
            </template>

            <template v-mutate="item" v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">

                <div class="text-center my-6">

                  <div v-if="item.multiple > 1">
                    Zestaw <b>{{item.multiple}}</b> ekranów, ilość segmentów na ekran: {{item.w * item.h}}
                  </div>
                  <div v-else>
                    Ekran o ilości segmentów: {{item.w * item.h}}
                  </div>

                  Wymiar: <span style="font-weight: 800">{{calculateScreenDimensions(item.w, item.h)}}</span>
                  <v-text-field
                      @input="itemAmountChanged(item)"
                      v-model="item.multiple"
                      :min="1" :max="maxAmountOfScreen" :rules="amountScreenRules"
                      oninput="if(Number(this.value) > Number(this.max)) this.value = this.max; if(Number(this.value) < Number(this.min)) this.value = this.min;" type="number" label="Ilość ekranów"
                      :value="item.multiple">
                  </v-text-field>

                  <led-variant-select
                      @variant-changed="itemAmountChanged(item)"
                      :led-config="item">
                  </led-variant-select>

                  <div>
                    <v-btn
                        class="mt-3"
                        outlined
                        :disabled="layout.length <= 1"
                        x-small
                        color="error"
                        @click="removeItem(item.i)">
                      Usuń konfigurację
                    </v-btn>
                  </div>
                </div>

              </td>
            </template>

          </v-data-table>

<!--          <v-btn color="primary" large>Złoż zamówienie</v-btn>-->
        </div>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
  </v-row>
</v-container>
</template>

<script>
import ledstore from "@/vuex/ledstore";
import LedScreenSelect from "@/components/products/led/LedScreenSelect";
import LedVariantSelect from "@/components/products/led/LedVariantSelect";

export default {

  components: {
    LedVariantSelect,
    LedScreenSelect
  },
  watch: {
    layout: {
      handler(oldValue, newValue) {

        this.summaryTableData = newValue;
      },
      deep: true
    },

    expanded: {
      handler(newValue) {
        this.onRowClicked(newValue[0])
      }
    }
  },

  data() {
    return {
      showPrice: false,
      ledVariants: ledstore.state.ledVariants,
      headers: [
        { text: 'Lp.', value: 'index' },
        { text: 'Ilość', value: 'multiple' },
        { text: 'Cena jedn.', value: 'screen_price' },
        { text: 'Cena zestawu', value: 'config_price' },
        { text: '', value: 'data-table-expand' },
      ],
      maxAmountOfScreen: 20,
      moduleWidth: ledstore.state.ledModuleWidth,
      moduleHeight: ledstore.state.ledModuleHeight,
      amountScreenRules: [
        value => {
          if((value || '') < this.maxAmountOfScreen)
            return true
          return 'Jesteśmy w stanie zaoferować maksymalnie '+this.maxAmountOfScreen+' takich ekranów.'
        }

      ],
    }
  },

  created() {

    this.summaryTableData = this.layout
  },

  computed: {
    price() {
      return ledstore.state.price
    },
    amount() {
      return ledstore.state.screenAmount
    },
    expanded: {
      get() {
        return [this.getLayoutById(ledstore.state.ledDisplayHoveredIndex)];
      },
      set(value) {
        return value
      }
    },

    layout() {
      let tableIndex = 1;
      return ledstore.state.ledLayout.map(obj => ({ ...obj, index: tableIndex++ }))
    },
    summaryTableData: {
      get() {
        return ledstore.state.ledLayout
      },
      set(newValue) {
        return newValue
      }
    },

    ledModuleWidth() {
      return ledstore.state.ledModuleWidth
    },
    ledModuleHeight() {
      return ledstore.state.ledModuleHeight
    }
  },

  methods: {

    onItemExpanded(item) {
      this.ledDisplayHovered(item.item.i)
    },

    onRowClicked(row) {
      this.ledDisplayHovered(row.i)
    },

    itemAmountChanged() {
      this.updateStoredLayout();
    },

    calculateScreenDimensions(w, h) {

      let screenWidth = this.moduleWidth * w;
      let screenHeight = this.moduleHeight * h;
      return screenWidth + "mm x " + screenHeight + "mm";
    },

    removeItem(item) {
      let newLayout = this.layout.filter(data => data.i !== item);
      ledstore.commit("setLedLayout", newLayout)
    },

    updateStoredLayout() {
      ledstore.commit("setLedLayout", this.layout)
    },

    getLayoutById(layoutId) {

      let layouts = this.layout;
      for(let i=0; i<layouts.length;i++) {
        if(layouts[i].i === parseInt(layoutId))
          return layouts[i]
      }
      return false;
    },

    ledDisplayHovered(displayIndex) {
      ledstore.commit("setLedDisplayHoveredIndex", displayIndex)
    },
  },

}
</script>

<style>
.v-list-item__content {
  text-align: left !important;
}

</style>
