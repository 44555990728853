<template>
  <v-card class="elevation-0 ma-0 text-left">
    <v-card-title>Dodaj ekran</v-card-title>
    <v-card-subtitle class="pb-0">Dodaj ekran do zamówienia</v-card-subtitle>
    <div class="ma-1">
      <v-container fluid>
        <v-row>
          <v-col class="pb-0">
            <led-variant-select :led-config="defaultLayout"></led-variant-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-text-field
                label="Ilość ekranów do dodania"
                type="number"
                :min="1" :max="maxAmountOfScreen" :rules="amountScreenRules" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max; if(Number(this.value) < Number(this.min)) this.value = this.min;"
                v-model="amountOfLedScreens">
            </v-text-field>
          </v-col>
          <v-col align-self="center"  sm="6">
            <v-btn
                block
                @click="addLedComponent(2, amountOfLedScreens)"
                color="primary"
                small>
              Dodaj {{amountOfLedScreens}} {{calculateLabelByAmountOfScreens(amountOfLedScreens)}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" v-if="parseInt(amountOfLedScreens) !== 1" >
            <v-btn block small color="#333333" dark @click="addMultipleLedComponents(2, amountOfLedScreens)">
              Wygeneruj 1 zestaw zawierający {{amountOfLedScreens}} {{calculateLabelByAmountOfScreens(amountOfLedScreens)}}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col align-self="center">
            <v-btn block small :disabled="layout.length <= 1" color="error" @click="layout.splice(1)">Przywróć szablon</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>

import ledstore from "@/vuex/ledstore";
import LedVariantSelect from "@/components/products/led/LedVariantSelect";

export default {
  components: {LedVariantSelect},
  data() {
    return {
      amountOfLedScreens: 1,
      maxAmountOfScreen: 100,
      defaultLayout: {
        led_variant: 1
      },
      index: 0,
      amountScreenRules: [
        value => {
          if((value || '') < this.maxAmountOfScreen)
            return true
          return 'Jesteśmy w stanie zaoferować maksymalnie '+this.maxAmountOfScreen+' takich ekranów.'
        }
      ],

    }
  },

  computed: {
    layout: {
      get() {
        return ledstore.state.ledLayout
      },
      set(newValue) {
        return newValue
      }
    }
  },

  methods: {

    calculateLabelByAmountOfScreens(amount) {
      switch (parseInt(amount)) {
        case 1:
          return "ekran";
        case 2:
        case 3:
        case 4:
          return "ekrany";
      }
      return "ekranów";
    },

    addMultipleLedComponents(width, amount = 1) {
      let label = "Zestaw " + amount + " ekranów";
      this.addLedComponent(width, 1, label, amount)
    },

    addLedComponent(width, amount = 1, label = "", multipleAmount = 1) {

      for (let i=0; i<amount; i++) {
        this.index++;
        this.layout.push({
          x: (this.layout.length * 2) % (this.colNum || 12),
          y: this.layout.length + (this.colNum || 12),
          w: width,
          h: 1,
          i: this.index,
          multiple: multipleAmount,
          label: label,
          led_variant: this.defaultLayout.led_variant
        });
      }
    }
  },

}
</script>
