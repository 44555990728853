<template>
  <v-container>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="calculateSummaryHoverIndexByHoveredId">
          <v-expansion-panel
              v-for="(item, index) in layout"
              :key="item.i"
          >
            <v-expansion-panel-header
                :class="item.i === hoveredIndex ? 'hovered-item' : 'item'"
                @mouseover="ledDisplayHovered(item.i)"
            >
              <div v-if="item.multiple > 1">
                {{index + 1}}. Zestaw {{item.multiple}} ekranów {{calculateScreenDimensions(item.w, item.h)}}
              </div>
              <div v-else>
                {{index + 1}}. Ekran {{calculateScreenDimensions(item.w, item.h)}}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left">
              <div v-if="item.multiple > 1">
                Zestaw {{item.multiple}} ekranów, ilość segmentów na ekran: {{item.w * item.h}}
              </div>
              <div v-else>
                Ekran o ilości segmentów: {{item.w * item.h}}
              </div>
              Wymiar: {{calculateScreenDimensions(item.w, item.h)}}

              <div class="my-4">
                <v-text-field :min="1" :max="maxAmountOfScreen" :rules="amountScreenRules" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max; if(Number(this.value) < Number(this.min)) this.value = this.min;" type="number" label="Ilość ekranów" v-model="item.multiple"></v-text-field>
              </div>

              <div>
                <v-btn :disabled="layout.length <= 1" small color="error" @click="removeItem(item.i)">
                  Usuń konfigurację
                </v-btn>
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ledstore from "@/vuex/ledstore";

export default {

  data() {
    return {
      maxAmountOfScreen: 20,
      moduleWidth: ledstore.state.ledModuleWidth,
      moduleHeight: ledstore.state.ledModuleHeight,
      amountScreenRules: [
          value => {
            if((value || '') < this.maxAmountOfScreen)
              return true
            return 'Jesteśmy w stanie zaoferować maksymalnie '+this.maxAmountOfScreen+' takich ekranów.'
          }

      ],
    }
  },

  watch: {
    layout: {
      handler() {
        this.updateStoredLayout()
      },
      deep: true
    },
  },

  computed: {
    price() {
      return ledstore.state.price
    },
    layout() {
      return ledstore.state.ledLayout
    },
    screenAmount() {
      return ledstore.state.screenAmount
    },
    hoveredIndex() {
      return ledstore.state.ledDisplayHoveredIndex
    },

    calculateSummaryHoverIndexByHoveredId() {

      let id = this.hoveredIndex;
      for (let i=0;i<this.layout.length;i++) {
        if(this.layout[i].i === id) {
          return i
        }
      }
      return -1;
    }
  },

  methods: {
    calculateScreenDimensions(w, h) {

      let screenWidth = this.moduleWidth * w;
      let screenHeight = this.moduleHeight * h;
      return screenWidth + "mm x " + screenHeight + "mm";
    },
    ledDisplayHovered(displayIndex) {
      ledstore.commit("setLedDisplayHoveredIndex", displayIndex)
    },

    removeItem(item) {
      let newLayout = this.layout.filter(data => data.i !== item);
      ledstore.commit("setLedLayout", newLayout)
    },

    updateStoredLayout() {
      ledstore.commit("setLedLayout", this.layout)
    },

  },
}
</script>

<style>

.item {

  color: #979797;
}

.hovered-item {

  font-weight: 800;
}

</style>
