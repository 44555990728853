<template>
  <v-select
      class="text-left"
      @input="onVariantChanged(ledConfig)" v-model="ledConfig.led_variant" :value="ledConfig.led_variant"
      :items="ledVariants"
      label="Wybierz rozdzielczość i ilość obsługiwanych kolorów ekranu"
      item-value="id"
      item-text="label"
  ></v-select>
</template>

<script>

import ledstore from "@/vuex/ledstore";

export default {

  props: {
    ledConfig: {
      type: Object,
      default: () => {}
    },
  },

  data() {
    return {
      ledVariants: ledstore.state.ledVariants
    }
  },

  methods: {
    onVariantChanged(ledConfig) {
      this.$emit('variant-changed', ledConfig)
    }
  },
}

</script>
